import { Button, ButtonProps } from '@swe/shared/ui-kit/components/button';

import Stack from '@swe/shared/ui-kit/components/stack';
import { range } from '@swe/shared/utils/array';

import { ReactNode } from 'react';

import styles from './styles.module.scss';

type Action = Pick<ButtonProps, 'icon' | 'onClick' | 'ariaLabel'>;

type Entry<DS> = {
  name: string;
  value: keyof DS | ((dataSource: DS) => string);
  action?: Action | ReactNode[];
};

type AttributeGridProps<DS> = {
  source: DS;
  entries: Entry<DS>[];
};

const EMPTY_VALUE = 'Not Specified';

const getWidth = (index: number) => {
  switch (index) {
    case 0:
      return '22%';
    case 1:
      return '100%';
    default:
    case 2:
      return '1%';
  }
};

const AttributeGrid = <DS extends Record<string, any>>({ source, entries }: AttributeGridProps<DS>) => {
  return (
    <table className={styles.root}>
      <colgroup>
        {range(0, 3).map((_, idx) => (
          <col
            key={idx}
            style={{ width: getWidth(idx), whiteSpace: 'nowrap' }}
          />
        ))}
      </colgroup>
      <tbody>
        {entries.map(({ name, value, action }) => (
          <tr key={name}>
            <td className={styles.attributeName}>{name}:</td>
            <td className={styles.attributeValue}>
              {(typeof value === 'function' ? value(source) : source[value]) ?? EMPTY_VALUE}
            </td>
            <td>
              {Array.isArray(action) ? (
                <Stack
                  className={styles.stack}
                  direction="row"
                >
                  {action.map((node) => node)}
                </Stack>
              ) : (
                action && (
                  <Button
                    size="sm"
                    color="light"
                    {...(action as ButtonProps)}
                  />
                )
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export type { AttributeGridProps, Entry };
export { AttributeGrid };
