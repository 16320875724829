import Badge from '@swe/shared/ui-kit/components/badge';
import Button from '@swe/shared/ui-kit/components/button';
import { EditIcon } from '@swe/shared/ui-kit/components/icon';
import Loader from '@swe/shared/ui-kit/components/loader';
import { formatDate, parseDateOnlyISO } from '@swe/shared/utils/date';
import { formatPhone } from '@swe/shared/utils/phone';

import { useCallback, useMemo } from 'react';

import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { AttributeGrid, Entry } from 'domains/profile/components/attribute-grid';
import { getFullName, User } from 'entities/authorization/user';

type GetEntriesArg = {
  editPhone: () => void;
  editBirthday: () => void;
  isPhoneVerified?: boolean;
  isDateOfBirthFilledFromDocuments?: boolean;
};

const getEntries = ({
  editPhone,
  editBirthday,
  isDateOfBirthFilledFromDocuments,
  isPhoneVerified,
}: GetEntriesArg): Entry<User>[] => [
  {
    name: 'Name',
    value: (user) => getFullName(user, 'Unknown'),
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'Date of Birth',
    value: ({ dateOfBirth }) => (dateOfBirth ? formatDate(parseDateOnlyISO(dateOfBirth)) : ''),
    action: isDateOfBirthFilledFromDocuments
      ? undefined
      : {
          icon: EditIcon,
          onClick: editBirthday,
          ariaLabel: 'Edit date of birth',
        },
  },
  {
    name: 'Phone',
    value: ({ phoneNumber }) => formatPhone(phoneNumber ?? ''),
    action: [
      isPhoneVerified === false ? (
        <Badge
          size="md"
          color="warning"
        >
          Not verified
        </Badge>
      ) : null,
      <Button
        ariaLabel="Edit phone"
        color="light"
        size="sm"
        icon={EditIcon}
        onClick={editPhone}
      />,
    ],
  },
];

const ProfileContacts = () => {
  const navigate = useRouterNavigate();
  const { user } = useCurrentUser();
  const editPhone = useCallback(() => navigate(Routes.ProfilePhoneUpdate), [navigate]);
  const editBirthday = useCallback(() => navigate(Routes.ProfileBirthdayUpdate), [navigate]);
  const entries = useMemo(
    () =>
      getEntries({
        editPhone,
        editBirthday,
        isDateOfBirthFilledFromDocuments: user?.isDateOfBirthFilledFromDocuments,
        isPhoneVerified: user?.isCustomerPhoneVerified,
      }),
    [editPhone, editBirthday, user?.isDateOfBirthFilledFromDocuments, user?.isCustomerPhoneVerified],
  );

  if (!user) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  return (
    <AttributeGrid
      source={user}
      entries={entries}
    />
  );
};

export { ProfileContacts };
