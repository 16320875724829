import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { ProfileContacts } from 'domains/profile/containers/contacts';

const TITLE = 'Contact Information';

const ProfileContactPage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.Profile}
        enableMarginBottom
      />
      <ProfileContacts />
    </Page>
  );
};

ProfileContactPage.getMeta = () => ({ title: TITLE });

ProfileContactPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileContactPage;
